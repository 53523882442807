<template>
  <div class="skimovieProblemsIssueSpots">
    <div class="row">
      <div class="col-12 col-md-6 col-xl-6">
        <h5 class="statisticTitle">
          Country Statistics
        </h5>
        <table
          v-if="summary && summary.countryStatistics"
          class="defaultTable"
        >
          <colgroup>
            <col>
            <col width="100">
            <col>
          </colgroup>
          <thead>
            <tr>
              <th>Country</th>
              <th>Total</th>
              <th>Active</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(stat, index) in summary.countryStatistics"
              :key="`SkimovieProblems-tr-${ index }`"
            >
              <td>{{ stat.country }}</td>
              <td>{{ stat.total }}</td>
              <td>
                <div class="progress">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    :style="`width: ${ stat.activePercentage }%`"
                    :aria-valuenow="stat.activePercentage"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    {{ stat.activePercentage }}%
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <p v-else>
          {{ $t('noDataAvailable') }}
        </p>
      </div>
      <div class="col-12 col-md-6 col-xl-6">
        <h5 class="statisticTitle">
          Type Statistics
        </h5>
        <table
          v-if="summary && summary.typeStatistics"
          class="defaultTable"
        >
          <colgroup>
            <col>
            <col width="100">
            <col>
          </colgroup>
          <thead>
            <tr>
              <th>Type</th>
              <th>Total</th>
              <th>Active</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(stat, index) in summary.typeStatistics"
              :key="`SkimovieProblems-tr-${ index }`"
            >
              <td>{{ stat.type }}</td>
              <td>{{ stat.total }}</td>
              <td>
                <div class="progress">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    :style="`width: ${ stat.activePercentage }%`"
                    :aria-valuenow="stat.activePercentage"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    {{ stat.activePercentage }}%
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <p v-else>
          {{ $t('noDataAvailable') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InstallationReportSummary',
    data () {
    return {
      summary: null,
    }
  },
  async created () {
    await this.getInstallationSummary();
  },
  methods: {
    async getInstallationSummary () {
      await this.axios.get('/Report/GetInstallationSummary')
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.summary = response.data;
          this.summary.typeStatistics.forEach(item => {
            if(!item.type)
            {
              item.type = 'Unknown';
            }
          });
          this.summary.countryStatistics.forEach(item => {
            if(!item.country)
            {
              item.country = 'Unknown';
            }
          });
          this.summary.typeStatistics.sort(function (a, b) {
            return b.total - a.total;
          });  
          this.summary.countryStatistics.sort(function (a, b) {
            return b.total - a.total;
          });  
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
}
</script>

<style scoped>
.skimovieProblemsIssueSpots {
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}
.statisticTitle {
  text-align: center;
}
@media (min-width: 600px) {
  .skimovieProblemsIssueSpots {
    max-height: 600px;
  }
}
</style>
